import React, { useEffect, useState } from "react";
import { TideItemForAgentPage } from "../../types";
import { useI18next } from "gatsby-plugin-react-i18next";
import { addYears, format, getDate, getMonth, getYear } from "date-fns";
import { getInvoices, printAction } from "../../tide-api";
import Icon from "../icon";
import DateSelector from "./dateselector";
import Loader from "../loader";
import { isEmpty } from "lodash";
import { buildClassName } from "../../utils";
import Pagination from "react-responsive-pagination";
import {
  AgentInvoiceItem,
  AgentPrintActionRequest,
  FilterItem,
} from "@qite/tide-client/build/types";

interface InvoiceListProps {
  pageData?: TideItemForAgentPage;
}

interface InvoiceType {
  key: number;
  value: string;
}

const InvoiceList: React.FC<InvoiceListProps> = ({ pageData }) => {
  const { t } = useI18next();

  const invoicePrintId = parseInt(process.env.INVOICE_PRINT_ID!);
  const itemsPerPage = 15;
  const creationDate = addYears(new Date(), -2);
  const purchaseInvoiceTypes = [1, 2];
  const commissionInvoiceType = 3;
  const invoiceTypes: InvoiceType[] = [
    { key: 0, value: "" },
    { key: 1, value: t("PORTAL.PURCHASE_INVOICE") },
    { key: 2, value: t("PORTAL.COMMISSION_INVOICE") },
  ];

  const [invoiceList, setInvoiceList] = useState<AgentInvoiceItem[]>([]);
  const [fetchInvoices, setFetchInvoices] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [doSearch, setDoSearch] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [createdDate, setCreatedDate] = useState<Date | null>(null);
  const [agentPrintActionRequest, setAgentPrintActionRequest] = useState<AgentPrintActionRequest>(
    {} as AgentPrintActionRequest
  );
  const [filter, setFilter] = useState<FilterItem>({
    take: itemsPerPage,
    page: 1,
    sort: {
      name: "dateCreated",
      asc: false,
    },
    columns: {
      dateCreated: {
        type: "dateFrom",
        value: {
          year: getYear(creationDate),
          month: getMonth(creationDate) + 1,
          day: getDate(creationDate),
        },
      },
      number: {
        type: "text",
        value: undefined,
      },
      dossierNumber: {
        type: "text",
        value: undefined,
      },
      type: {
        type: "notEqualId",
        value: 0,
      },
      total: {
        type: "numeric",
        value: undefined,
      },
      paidTotal: {
        type: "numeric",
        value: undefined,
      },
    },
  } as FilterItem);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    (async () => {
      try {
        let token: string | null = null;
        if (typeof localStorage !== "undefined") {
          token = localStorage.getItem("token");
        }

        if (token && filter && doSearch) {
          setFetchInvoices(true);
          setError(false);
          setDoSearch(false);
          const response = await getInvoices(filter, token, signal);
          if (response) {
            setInvoiceList(response.items);

            const totalPages = Math.ceil(response.count / itemsPerPage);
            setTotalPages(totalPages);
          }
        }
      } catch (error) {
        setError(true);
      } finally {
        setFetchInvoices(false);
      }
    })();
  }, [doSearch]);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    (async () => {
      let token: string | null = null;
      if (typeof localStorage !== "undefined") {
        token = localStorage.getItem("token");
      }

      if (token && agentPrintActionRequest && agentPrintActionRequest.id > 0) {
        const printResult = await printAction(agentPrintActionRequest, token, signal);
        if (printResult) {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(printResult);
          link.target = "_blank";
          link.download = agentPrintActionRequest.dossierNumber + ".pdf";
          document.body.append(link);
          link.click();
          link.remove();
        }
        setAgentPrintActionRequest({} as AgentPrintActionRequest);
      }
    })();
  }, [agentPrintActionRequest]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, type?: string) => {
    const target = event.target;
    const value = target.value;
    const name = target.id;

    setFilter({
      ...filter,
      page: 1,
      columns: {
        ...filter.columns,
        [name]: {
          type: type ?? "text",
          value: value !== "" ? value : undefined,
        },
      },
    });
  };

  const handleDateChange = (date: Date | null, id: string) => {
    switch (id) {
      case "createdDate":
        setCreatedDate(date);
        break;
      default:
        break;
    }
    const dateValue = date
      ? {
          year: getYear(date),
          month: getMonth(date) + 1,
          day: getDate(date),
        }
      : undefined;
    setFilter({
      ...filter,
      page: 1,
      columns: {
        ...filter.columns,
        [id]: {
          type: !dateValue && id === "createdDate" ? "dateFrom" : "date",
          value:
            !dateValue && id === "createdDate"
              ? {
                  year: getYear(creationDate),
                  month: getMonth(creationDate) + 1,
                  day: getDate(creationDate),
                }
              : dateValue,
        },
      },
    });
    setDoSearch(true);
  };

  const handleDropDownTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const target = event.target;
    const value = target.value;
    const name = target.id;

    const queryType = defineInvoiceQueryType(value);

    setFilter({
      ...filter,
      page: 1,
      columns: {
        ...filter.columns,
        [name]: {
          type: queryType.type,
          value: queryType.value,
        },
      },
    });
    setDoSearch(true);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setDoSearch(true);
    }
  };

  const changePage = (page: number) => {
    setCurrentPage(page);
    setFilter({ ...filter, page: page });
    setDoSearch(true);
  };

  const handleSort = (sortField: string) => {
    if (filter.sort.name === sortField) {
      setFilter({ ...filter, sort: { name: sortField, asc: !filter.sort.asc } });
    } else {
      setFilter({ ...filter, sort: { name: sortField, asc: true } });
    }
    setCurrentPage(1);
    setDoSearch(true);
  };

  const defineInvoiceQueryType = (value: string) => {
    switch (value) {
      case "1":
        return { type: "inList", value: purchaseInvoiceTypes };
      case "2":
        return { type: "int", value: commissionInvoiceType };
      default:
        return { type: "notEqualId", value: 0 };
    }
  };

  const defineInvoiceTypeName = (type: number): string => {
    switch (type) {
      case 3:
        return t("PORTAL.COMMISSION_INVOICE");
      default:
        return t("PORTAL.PURCHASE_INVOICE");
    }
  };

  const handlePrintAction = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    invoice: AgentInvoiceItem,
    actionId: number
  ) => {
    event.preventDefault();
    setAgentPrintActionRequest({
      ...agentPrintActionRequest,
      id: actionId,
      dossierNumber: invoice.dossierNumber,
      invoiceId: invoice.id,
    });
  };

  return (
    <section className="section">
      <div className="portal">
        <h2>{pageData?.content?.general?.title}</h2>
        <div className="portal__container">
          <div className="portal__wrapper">
            <div className="portal__header">
              <div className="portal__header--items portal__header--items--invoice">
                <div className="portal__header--item"></div>
                <div className="portal__header--item">
                  <label
                    className="form__label portal__header--label portal__header--label-sort-desc"
                    onClick={() => handleSort("number")}
                  >
                    {t("PORTAL.INVOICE")}
                    <Icon name="sort" />
                  </label>
                  <input
                    type="text"
                    className="form__input"
                    placeholder=""
                    value={filter.columns["number"].value}
                    id="number"
                    onChange={(event) => handleInputChange(event)}
                    onKeyDown={(e) => handleKeyPress(e)}
                  />
                </div>
                <div className="portal__header--item">
                  <label
                    className="form__label portal__header--label portal__header--label-sort-desc"
                    onClick={() => handleSort("type")}
                  >
                    {t("PORTAL.TYPE")}
                    <Icon name="sort" />
                  </label>
                  <select
                    className="form__input"
                    defaultValue={undefined}
                    onChange={(event) => handleDropDownTypeChange(event)}
                    id="type"
                  >
                    {invoiceTypes.map((invoiceType, index) => {
                      return (
                        <option key={index} value={invoiceType.key}>
                          {invoiceType.value}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="portal__header--item">
                  <label
                    className="form__label portal__header--label portal__header--label-sort-desc"
                    onClick={() => handleSort("dossierNumber")}
                  >
                    {t("PORTAL.DOSSIER")}
                    <Icon name="sort" />
                  </label>
                  <input
                    type="text"
                    className="form__input"
                    placeholder=""
                    value={filter.columns["dossierNumber"].value}
                    id="dossierNumber"
                    onChange={(event) => handleInputChange(event)}
                    onKeyDown={(e) => handleKeyPress(e)}
                  />
                </div>
                <div className="portal__header--item">
                  <label
                    className="form__label portal__header--label portal__header--label-sort-desc"
                    onClick={() => handleSort("total")}
                  >
                    {t("PORTAL.TOTAL")}
                    <Icon name="sort" />
                  </label>
                  <input
                    type="text"
                    className="form__input"
                    placeholder=""
                    value={filter.columns["total"].value}
                    id="total"
                    onChange={(event) => handleInputChange(event, "number")}
                    onKeyDown={(e) => handleKeyPress(e)}
                  />
                </div>
                <div className="portal__header--item">
                  <label
                    className="form__label portal__header--label portal__header--label-sort-desc"
                    onClick={() => handleSort("paidTotal")}
                  >
                    {t("PORTAL.PAIDTOTAL")}
                    <Icon name="sort" />
                  </label>
                  <input
                    type="text"
                    className="form__input"
                    placeholder=""
                    value={filter.columns["paidTotal"].value}
                    id="paidTotal"
                    onChange={(event) => handleInputChange(event, "number")}
                    onKeyDown={(e) => handleKeyPress(e)}
                  />
                </div>
                <div className="portal__header--item">
                  <label
                    className="form__label portal__header--label portal__header--label-sort-desc"
                    onClick={() => handleSort("dateCreated")}
                  >
                    {t("PORTAL.CREATED")}
                    <Icon name="sort" />
                  </label>
                  <DateSelector id="createdDate" date={createdDate} onChange={handleDateChange} />
                </div>
              </div>
            </div>
            <div className="portal__list">
              {fetchInvoices ? (
                <Loader loaderText={t("PORTAL.LOADING_INVOICES")} />
              ) : isEmpty(invoiceList) ? (
                <div
                  className={buildClassName([
                    "portal__list--empty",
                    error && "portal__list--error",
                  ])}
                >
                  {t(error ? "PORTAL.ERROR" : "PORTAL.NO_INVOICES")}
                </div>
              ) : (
                invoiceList.map((invoice, index) => {
                  return (
                    <div className="portal__list--items portal__list--items--invoice" key={index}>
                      <div className="portal__list--btn">
                        <button className="cta cta--secondary cta--withicon portal__list--icon">
                          <Icon name="print" className="icon--ui-print"></Icon>
                          <Icon name="arrow" className="icon--arrow"></Icon>
                        </button>
                        <div className="portal__dropdown">
                          <a
                            href="#"
                            className="portal__dropdown--link"
                            onClick={(e) => handlePrintAction(e, invoice, invoicePrintId)}
                          >
                            <Icon name="print" className="icon--ui-print"></Icon>
                            {t("PORTAL.PRINT_INVOICE_TW")}
                          </a>
                        </div>
                      </div>
                      <div className="portal__list--item">
                        <span>{invoice.number}</span>
                      </div>
                      <div className="portal__list--item">
                        <span>{defineInvoiceTypeName(invoice.type)}</span>
                      </div>
                      <div className="portal__list--item">
                        <span>{invoice.dossierNumber}</span>
                      </div>
                      <div className="portal__list--item">
                        <span>{invoice.total.toFixed(2)}</span>
                      </div>
                      <div className="portal__list--item">
                        <span>{invoice.paidTotal.toFixed(2)}</span>
                      </div>
                      <div className="portal__list--item portal__list--item-right">
                        <span>{format(new Date(invoice.dateCreated), "dd-MM-yyyy")}</span>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
          <div className="portal__footer">
            <Pagination
              current={currentPage}
              total={totalPages}
              onPageChange={(page) => changePage(page)}
              maxWidth={400}
              pageItemClassName="portal__footer--page"
              activeItemClassName="portal__footer--page-active"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default InvoiceList;
